import React from "react";
import Layout from "../../../other/layout";
import ForfaitsEtTarifs from "../../../components/AC-StaticPages/Demarrez/ConceptionDeLogo/ForfaitsEtTarifs/ForfaitsEtTarifs";

import SEO from "../../../other/seo";

export default function conceptionDeLogo() {
  return (
    <Layout>
      <SEO title="Forfaits et tarifs" />
      <ForfaitsEtTarifs />
    </Layout>
  );
}
