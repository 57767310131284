import React from "react";
import { Link } from "gatsby";
import "../../../../../assets/css/global.css";
import styles from "./styles.module.css";
import Button from "../../../components/Button";

export default function ForfaitsEtTarifs() {
  const buttonMargin = "15px auto 10px";
  const iconColor = "#538B23";
  return (
    <div className={styles.container}>
      <h1>Forfaits et tarifs</h1>
      <h2 style={{ margin: "20px 0 30px", color: "black" }}>
        Qualité supérieure, logos professionnels, assistance à toutes les
        étapes.
      </h2>
      <div className={styles.row}>
        <table className={styles.table}>
          <tr>
            <td>&nbsp;</td>
            <th
              className={styles.table_header}
              style={{ backgroundColor: "#D2E2BD" }}
            >
              <h3>Forfait Démarrage 99 $</h3>
              <Link
                maxWidth={"200px"}
                margin={buttonMargin}
                href="/conception-de-logo/STARTER"
                className="forfaitsTableBtn"
              >
                COMMANDEZ DÈS MAINTENANT
              </Link>
            </th>
            <th
              className={styles.table_header}
              style={{ backgroundColor: "#cd7f32" }}
            >
              <h3>Forfait Bronze 195 $</h3>
              <Link
                maxWidth={"200px"}
                margin={buttonMargin}
                href="/conception-de-logo/BRONZE"
                className="forfaitsTableBtn"
              >
                COMMANDEZ DÈS MAINTENANT
              </Link>
            </th>
            <th
              className={styles.table_header}
              style={{ backgroundColor: "#C0C0C0" }}
            >
              <h3>Forfait Argent 395 $</h3>
              <Link
                maxWidth={"200px"}
                margin={buttonMargin}
                href="/conception-de-logo/ARGENT-SILVER"
                className="forfaitsTableBtn"
              >
                COMMANDEZ DÈS MAINTENANT
              </Link>
            </th>
            <th
              className={styles.table_header}
              style={{ backgroundColor: "#FFD700" }}
            >
              <h3>Forfait Or 645 $</h3>
              <Link
                maxWidth={"200px"}
                margin={buttonMargin}
                href="/conception-de-logo/ORGOLD"
                className="forfaitsTableBtn"
              >
                COMMANDEZ DÈS MAINTENANT
              </Link>
            </th>
          </tr>
          <tr className={styles.table_category}>
            <th>
              <h3>Conception de logos</h3>
            </th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th>Conception de logos</th>
            <td>2</td>
            <td>4</td>
            <td>6</td>
            <td>10</td>
          </tr>
          <tr>
            <th>Équipe de conception interne</th>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>
              Des concepteurs professionnels quitravaillent sur votre projet
            </th>
            <td>1</td>
            <td>2</td>
            <td>4</td>
            <td>8</td>
          </tr>
          <tr>
            <th>Délai d’exécution du concept initial</th>
            <td>5 jours</td>
            <td>5 jours</td>
            <td>5 jours</td>
            <td>5 jours</td>
          </tr>
          <tr>
            <th>Révision de concepts</th>
            <td>2</td>
            <td>3</td>
            <td>Illimité</td>
            <td>Illimité</td>
          </tr>
          <tr>
            <th>Communication directe</th>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>Satisfaction garantie</th>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>Signatures de courriel personnalisées</th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr className={styles.table_category}>
            <th>
              <h3>Modèles pour votre papeterie</h3>
            </th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th>Cartes professionnelles, papiers à en-tête & enveloppes</th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>Une variété de concepts parmi lesquels choisir</th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>3</td>
            <td>3</td>
          </tr>
          <tr>
            <th>Révisions des concepts de papeterie</th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>Illimité</td>
            <td>Illimité</td>
          </tr>
          <tr className={styles.table_category}>
            <th>
              <h3>Fichiers finaux</h3>
            </th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th>Vous êtes le propriétaire de tous les droits de conception</th>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>Fichiers envoyés par courriel</th>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>Fichier en format vectoriel</th>
            <td>&nbsp;</td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>Version de votre logo en noir et blanc</th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>Stockage de fichiers à vie</th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <th>Polices et codes de couleurs</th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr className={styles.table_category}>
            <th>
              <h3>Impression entièrement en couleur</h3>
            </th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th>1,000 cartes professionnelles imprimées</th>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <i class="material-icons" style={{ color: "#155a7e" }}>
                check
              </i>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <Link
                maxWidth={"200px"}
                margin={buttonMargin}
                href="/conception-de-logo/STARTER"
                className="forfaitsTableBtn"
              >
                COMMANDEZ DÈS MAINTENANT
              </Link>
            </td>
            <td>
              <Link
                maxWidth={"200px"}
                margin={buttonMargin}
                href="/conception-de-logo/BRONZE"
                className="forfaitsTableBtn"
              >
                COMMANDEZ DÈS MAINTENANT
              </Link>
            </td>
            <td>
              <Link
                maxWidth={"200px"}
                margin={buttonMargin}
                href="/conception-de-logo/ARGENT-SILVER"
                className="forfaitsTableBtn"
              >
                COMMANDEZ DÈS MAINTENANT
              </Link>
            </td>
            <td>
              <Link
                maxWidth={"200px"}
                margin={buttonMargin}
                href="/conception-de-logo/ORGOLD"
                className="forfaitsTableBtn"
              >
                COMMANDEZ DÈS MAINTENANT
              </Link>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
